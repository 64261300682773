<template>
  <SelectedSignalStyled>
    <button class="toggle button-part" @click="isExpanded = !isExpanded">
      <ChevronRightIcon class="icon" :class="{ rotate: isExpanded }" />
      <div class="name">
        <span class="signal-name">{{ signal.translatedName }}&nbsp;</span>
        <span class="signal-aggregator" v-if="selectedAggregator">({{ selectedAggregator.label }})</span>
      </div>
    </button>
    <transition-group name="fade-in-right" tag="div" class="stats button-part">
      <template v-if="signal.selectedInterval && signalData">
        <div class="min" :key="signal.signalId + '_min'">
          <LoadingDots v-if="signal.isLoading || $apollo.queries.signalData.loading" />
          <template v-else>
            <div class="label">Min.</div>
            <div v-if="signalData.minimum === null" class="value">-</div>
            <div v-else class="value">{{ signalData.minimum | round(0) }}</div>
          </template>
        </div>
        <div class="max" :key="signal.signalId + '_max'">
          <LoadingDots v-if="signal.isLoading || $apollo.queries.signalData.loading" />
          <template v-else>
            <div class="label">Max.</div>
            <div v-if="signalData.maximum === null" class="value">-</div>
            <div v-else class="value">{{ signalData.maximum | round(0) }}</div>
          </template>
        </div>
        <div class="mean" :key="signal.signalId + '_mean'">
          <LoadingDots v-if="signal.isLoading || $apollo.queries.signalData.loading" />
          <template v-else>
            <div class="label">Ø</div>
            <div v-if="signalData.mean === null" class="value">-</div>
            <div v-else class="value">{{ signalData.mean | round(0) }}</div>
          </template>
        </div>
      </template>
    </transition-group>
    <VPopover class="button-part color" :placement="'top'" :popperOptions="popperOptions">
      <ColorBadgeStyled :color="signal.color" />
      <template v-slot:popover>
        <ColorPicker :color="signal.color" @update:color="updateColor" />
      </template>
    </VPopover>
    <button class="hide button-part" @click="toggleSignalHidden">
      <EyeOffIcon v-if="signal.isHidden" />
      <EyeIcon v-else />
    </button>
    <button class="delete button-part" @click="toggleSignalSelected">
      <TrashIcon />
    </button>
    <transition name="fade">
      <div class="details" v-if="isExpanded">
        <div>
          {{ $tc('signals.aggregation.label', 1) }}
        </div>
        <div class="aggregators">
          <AggregatorButtonStyled
            v-for="aggregator in availableAggregatorsMapped"
            :key="aggregator.id"
            :class="{ active: aggregator === selectedAggregator }"
            @click="setSignalAggregator(aggregator)"
          >
            {{ aggregator.label }}
          </AggregatorButtonStyled>
        </div>
      </div>
    </transition>
  </SelectedSignalStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { Interval } from 'luxon'
import { VPopover } from 'v-tooltip'
import { ChevronRightIcon, EyeIcon, EyeOffIcon, TrashIcon } from 'vue-feather-icons'
import { flexCenter, flexStartCenter, buttonReset } from '@styles/mixins'
import { ButtonSolid } from '@styles/buttons'
import { LoadingDots } from '@common/components'
import ColorPicker from '@/components/Misc/ColorPicker'
import round from '@/utils/round'
import { useStore } from '../../../../stores/signals'

import SIGNAL_DATA_QUERY from '#/graphql/signals/signalData.gql'
import AVAILABLE_AGGREGATORS_QUERY from '#/graphql/misc/availableAggregators.gql'

const SelectedSignalStyled = styled('div')`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr min-content 2.5rem 2.5rem 2.5rem;

  > .button-part {
    height: 2rem;
    margin-right: 1px;
    overflow: hidden;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      margin-right: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .toggle {
    ${buttonReset}
    ${flexStartCenter}
    color: ${({ theme }) => theme.colors.textPrimary};
    background: ${({ theme }) => theme.colors.backgroundPrimary};
    flex-grow: 1;
    font-size: 12px;
    .icon {
      flex-shrink: 0;
      overflow: hidden;
      transition: transform 0.25s;
      &.rotate {
        transform: rotate(90deg);
      }
    }
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .stats {
    ${flexCenter}
    display: none;
    flex-shrink: 0;
    align-items: stretch;
    min-width: 200px;
    background: ${({ theme }) => theme.colors.backgroundPrimary};

    .min,
    .max,
    .mean {
      ${flexCenter}
      margin: 0 .5rem;

      .label {
        font-size: 12px;
        min-width: 20px;
        text-align: right;
      }

      .value {
        ${flexCenter}
        font-size: 10px;
        margin-left: 0.5rem;
        width: 40px;
        overflow: hidden;
        height: calc(100% - 0.5rem);
        color: ${({ theme }) => theme.textPrimary};
        background: ${({ theme }) => chroma(theme.colors.white).alpha(0.4).css()};
      }
    }
    @media (min-width: 768px) {
      display: flex;
    }
  }
  .hide,
  .delete,
  .color {
    flex-shrink: 0;
    ${buttonReset}
    ${flexCenter}
    background: ${({ theme }) => theme.colors.backgroundPrimary};

    .feather {
      width: 20px;
      height: 20px;
      margin: 0 0.3rem;
    }
  }
  .details {
    grid-column: span 5;
    margin: 0 1rem;
    padding: 1rem;
    background: ${({ theme }) => chroma(theme.colors.backgroundPrimary).darken(0.5).css()};
    display: grid;
    grid-template-columns: minmax(min-content, max-content) 1fr;
    justify-items: end;
    align-items: center;
    grid-gap: 0.5rem;
    font-size: 12px;

    .aggregators {
      justify-self: start;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
`

const ColorBadgeStyled = styled('div')`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 0.3rem;
  background: ${p => p.color};
`

const AggregatorButtonStyled = styled(ButtonSolid)`
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${props => (props.theme.isDark ? props.theme.colors.white : props.theme.colors.black)};
  font-size: 12px;
  &.active {
    color: ${props => (props.theme.isDark ? props.theme.colors.black : props.theme.colors.white)};
    background: ${({ theme }) => theme.colors.primary};
  }
`

export default {
  props: {
    signal: {
      type: Object,
      required: true,
    },
  },
  components: {
    SelectedSignalStyled,
    ChevronRightIcon,
    EyeIcon,
    EyeOffIcon,
    TrashIcon,
    VPopover,
    ColorBadgeStyled,
    ColorPicker,
    LoadingDots,
    AggregatorButtonStyled,
  },
  filters: {
    round,
  },
  setup() {
    const signalStore = useStore()
    return {
      signalStore,
    }
  },
  data() {
    return {
      signalData: {},
      isExpanded: false,
      availableAggregators: [],
    }
  },
  computed: {
    selectedAggregator() {
      return this.availableAggregatorsMapped.find(f => f.id === this.signal.aggregator)
    },
    availableAggregatorsMapped() {
      return this.availableAggregators.map(({ name }) => ({
        id: name,
        label: this.$t('signals.aggregation.enums.' + name.toLowerCase()),
      }))
    },
    interval() {
      return Interval.fromISO(this.signal.selectedInterval)
    },
    validInterval() {
      return this.interval.isValid
    },
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
  },
  methods: {
    async toggleSignalSelected() {
      this.signalStore.unselectSignal(this.signal.signalId, this.signal.asset.id)
      this.trackSignalEvent('Signal Removed')
    },
    async updateColor(color) {
      const css = chroma(color.rgba).css()
      this.signalStore.setColorForSignal(this.signal.signalId, this.signal.asset.id, css)
    },
    async setSignalAggregator({ id }) {
      this.signalStore.setAggregationForSignal(this.signal.signalId, this.signal.asset.id, id)
      this.trackSignalEvent('Signal Aggregator Updated', id)
    },
    async toggleSignalHidden() {
      this.signalStore.toggleSignalIsHidden(this.signal.signalId, this.signal.asset.id)
    },
    trackSignalEvent(action, aggregator) {
      const aggregation = aggregator || this.signal.aggregator

      const eventData = {
        shortName: this.signal.shortName,
        signalId: this.signal.signalId,
        aggregator: aggregation,
        assetName: this.signal.asset.name,
      }
      // Track the event as a JSON object
      this.$matomo?.trackEvent('Signals', action, JSON.stringify(eventData), 0)
    },
  },
  apollo: {
    availableAggregators: {
      query: AVAILABLE_AGGREGATORS_QUERY,
      variables() {
        return {
          enumName: 'SignalAggregatorEnum',
        }
      },
      update: ({ availableAggregators }) => availableAggregators.enumValues,
    },
    signalData: {
      query: SIGNAL_DATA_QUERY,
      variables() {
        return {
          assetId: this.signal.asset.id,
          signalId: this.signal.signalId,
          signalShortName: this.signal.shortName,
          timeRange: {
            from: this.interval.start.toISO(),
            to: this.interval.end.toISO(),
            granularity: 'PT1S',
          },
          aggregator: this.signal.aggregator,
        }
      },
      skip() {
        return !this.signal || !this.signal.asset?.id || !this.validInterval || !this.signal.aggregator
      },
    },
  },
}
</script>
